.pagination {
	margin-top: $padding-rythm / 2;
	width: 100%;
	text-align: center;

	&--link {
		padding: 8px;
		background: white;
		border: 1px solid $gray;
		box-shadow: $drop-shadow;
		text-decoration: none;
		width: 40px;
		height: 40px;

		&:not(:last-of-type) {
			margin-right: 10px;
		}

		&__current, &:hover {
			border-color: $purple;
		}
	}
}
