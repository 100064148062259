.home {

	&--midsection {
		&-wrap {
			display: flex;
			flex-wrap: wrap;
			padding: 0 10px;
			@extend %wrap;
		}

		&-message {
			flex-basis: 100%;
			display: flex;
			justify-content: flex-end;
		}
	}

	&--column {
		flex-basis: 100%;
		z-index: 1;
		padding: 0;

		& > h2 {
			margin-bottom: $padding-rythm / 3;
			& > img {
				width: 25px;
				margin-bottom: -4px;
			}
		}

		& > * {
			margin-top: $padding-rythm / 2;
		}
	}

	&--opening-hours {
		width: 100%;
		font-size: $fs-body-sm;

		&-day {
			font-weight: bold;
		}

		td {
			padding-bottom: $padding-rythm / 3;
		}
	}

	&--message {
		display: flex;
		font-size: 14px;

		&__right {
			justify-content: flex-end;
		}

		& > img {
			width: 60px;
			height: 40px;
		}

		img + .rich-text,
		.rich-text + img {
			margin-left: 10px;
		}
	}

	&--after-hours {
		width: 100%;
	}

	&--after-hours-title,
	&--after-hours-number {
		padding-bottom: $padding-rythm / 3;
	}

	&--after-hours-title {
		font-weight: bold;
	}

	&--after-hours-number {
		white-space: nowrap;
	}

	&--location-button {
		border: 2px $blue solid;
		padding: 15px 0;
		width: 100%;
		text-align: center;
		font-weight: bold;
	}

	&--emergencies {
		padding: 10px;
		position: relative;
		background: white;

		& > h2 {
			margin-bottom: $padding-rythm / 2;
			font-size: 2em;
		}

		&:after {
			content: ' ';
			position: absolute;
			width: calc(100% + 8px);
			height: calc(100% + 8px);
			top: -4px;
			right: -4px;
			z-index: -1;
			background: $stripe;

		}
	}

	&--cta {
		& > svg {
			width: 16px;
			margin-bottom: -2px;
			margin-right: 4px;
		}
	}
}

@include from($bp-small) {
	.home {
		&--midsection {
			background: $purple;
			color: white;

			&-wrap {
				padding: 0 $padding-rythm / 2 $padding-rythm * 3;
			}

			&-message {
				margin-top: $padding-rythm;
			}
		}
		&--opening-hours {
			font-size: 20px;

			& > li {
				margin-bottom: $padding-rythm / 3;
			}
		}

		&--location-button {
			display: none;
		}

		&--after-hours {
			font-size: 21px;
		}

		&--column {
			flex-basis: 50%;
			padding: 0 $padding-rythm;
			> * {
				& ~ h2 {
					margin-top: $padding-rythm;
				}
			}

			& > h2 {
				margin-bottom: $padding-rythm - 10px;
				& > img {
					width: 40px;
					margin-bottom: -6px;
				}
			}

			&__flexed {
				flex-basis: 100%;
				display: flex;
				justify-content: space-between;
				flex-wrap: wrap;
			}

			@for $i from 1 through 3 {
				&__order-#{$i} {
					order: $i;
				}
			}
		}

		&--emergencies {
			padding: 30px 25px;
			margin-left: -$padding-rythm / 2;
			background: $purple;

			& > h2 {
				margin-bottom: $padding-rythm / 2;
			}
		}

		&--cta {
			& > svg > polygon {
				fill: $purple !important;
			}
		}
	}
}

@include from($bp-large) {
	.home {
		&--column {
			flex-basis: calc(100% / 3);
			order: initial;
		}

		&--midsection {
			&-message {
				margin-top: 0;
			}
		}
	}
}
