.l-blog-index {
    @extend %wrap;
    margin-top: 110px; // 110 - 90 offset
    padding: 0 10px;

    &--featured {
        margin-bottom: 20px;
        display: flex;
        flex-direction: column;
    }

    &--resources {
        margin-bottom: 20px;
        background-color: $light-grey;
        padding: 20px;

        &-title {
            font-size: $fs-body-large;
            margin-bottom: $padding-rythm / 3;
        }

        &-link {
            display: inline-block;
            margin-bottom: 8px;
            margin-right: 15px;
        }
    }

    &--load-more {
        font-size: $fs-body-large;
        margin-top: $padding-rythm / 2;
        display: flex;
        justify-content: center;
    }

    &--filtered-by {
        background-color: $purple;
        color: white;
        font-weight: bold;
        padding: 80px 20px;
        font-size: $fs-body-large;
    }

    &--cta {
        margin-top: $padding-rythm;
        position: relative;
        &-text {
            background-color: $purple;
            color: white;
            padding: 20px;
            z-index: 2;
            position: relative;
            
            .btn {
                font-size: 18px;
            }
        }
        
        &-title {
            font-size: $fs-body-large;
            line-height: 1.5;
            font-weight: bold;
            margin-bottom: $padding-rythm / 2;
            
        }

        &-image {
            & > img {
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                object-fit: cover;
            }
        }

        &-form {
            display: grid;
            grid-template-areas: 'input button'
                                 'error error';
            width: 100%;
            column-gap: 20px;

            & input {
                grid-area: input;
                background-color: $purple;
                outline: 0;
                border: 0;
                appearance: none;
                border-bottom: 2px solid white;
                color: white;
                padding: 10px 0;
                font-weight: bold;
                font-size: 18px;
                min-width: 0px;
                &::placeholder {
                    color: currentColor;
                    opacity: 1;
                }
                
            }

            & .btn {
              grid-area: button;  
            }

            &-error {
                padding-top: 15px;
                grid-area: error;
            }
        }

    }

    @include to($bp-medium) {
        &--load-more {
            > .btn {
                width: 100%;
            }
        }

        &--cta {
            display: flex;
            flex-direction: column;

            &-image {
                display: flex;
            }

            &-text {
                & > .btn {
                    // button not in a form
                    text-align: center;
                    width: 100%;
                }
            }
        }
    }

    @include from($bp-medium) {
        margin-top: $padding-rythm / 3;
        padding: 0 $padding-rythm * 1.5;

        %fixed-size-button {
            > .btn {
                padding: 0;
                width: 315px;
                height: 65px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .btn {
            font-size: $fs-body-large;
        }

        &--resources {
            padding: 40px;
            margin-bottom: $padding-rythm;
            &-title {
                margin-top: $heading-offset;
                font-size: $fs-h2;
            }
            &-link {
                margin-right: 20px;
            }
        }
        &--featured {
            margin-bottom: $padding-rythm;

            &-list {
                font-size: 18px;
            }
        }

        &--filtered-by {
            font-size: $fs-h2;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 100px 40px;
        }

        &--load-more {
            margin-top: $padding-rythm * 1.5;
            @extend %fixed-size-button;
        }

        &--cta {
            $cta: &;
            margin-top: $padding-rythm * 3;
            padding: $padding-rythm 0;
            
            &-text {
                @extend %fixed-size-button;
                padding: $padding-rythm 40px;
                width: 60%;

            }

            &-title {
                width: 88%;
                font-size: $fs-h2;
                line-height: 56px;
                margin-top: $heading-offset;
                margin-bottom: $padding-rythm * 1.5;
            }

            &-image {
                position: absolute;
                height: 100%;
                width: 100%;
                max-width: 60%;
                object-fit: cover;
                right: 0;
                top: 0;
                z-index: 1;
            }

            &-form {
                & input {
                    font-size: 30px;
                    padding: 12px 0;
                }

                & > .btn {
                    padding: 0.7em 1.2em;
                }
            }

            &__reversed {
                #{$cta} {
                    &-image {
                        left: 0;
                        right: auto;
                    }
                    &-text {
                        margin-left: auto;
                    }
                }
            }
        }
    }
}