.quote-block {
	font-size: 29px;
	border: 1px solid $border-colour;
	font-family: $curly-font;
	padding: $padding-rythm / 2;
	margin-bottom: $padding-rythm * 1.5;
	background: white;
	line-height: 1.2;
	@include box-shadow-stripe();
}
