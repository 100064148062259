.banner {
	height: 265px;
	position: relative;

	&--wrap {
		@extend %wrap;
		height: 100%;
		position: relative;
	}

	&--image {
		height: 100%;
		width: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		bottom: -10px;
		z-index: 10;
	}

	&--overlay {
		display: none;
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		width: 100%;
		background: $stripe-25;
		z-index: 20;
	}

	&--text {
		color: white;
		position: absolute;
		bottom: -10px;
		margin: 0 10px;
		z-index: 30;

		&-inner {
			position: relative;
			z-index: 30;
			padding: $padding-rythm / 3;
			width: 100%;
			height: 100%;
			background-color: $purple;
		}

		& > h1 {
			margin-bottom: $padding-rythm / 2;
		}
	}

	&--cta{
		font-size: $fs-body;
		display: block;
		font-weight: bold;
	}

	&__home {
		.banner {
			&--text {
				&-inner {
					background-color: white;
					color: $purple;
					border: 1px $border-colour solid;
				}
			}
		}
	}

	&--blog {
		&-intro {
			font-weight: bold;
			margin-top: 15px;
		}
		&-topics {
			margin-top: 15px;
			display: flex;
			& a {
				text-transform: uppercase;
				font-size: 12px;
				display: inline-block;
				white-space: nowrap;
				margin-right: 14px;
				margin-bottom: 8px;
			}
			& span {
				display: none;
				margin-right: 14px;
				font-weight: bold;
				white-space: nowrap;
			}
		}
	}
}

@include to($bp-small) {
	.banner {
		&__blog {
			.banner {
				&--text {
					bottom: -90px;
				}
			}
		}
	}
}


@include from ($bp-small) {
	.banner {
		height: 600px;
		&--overlay {
			display: block;
		}

		&--wrap {
			padding: 0 $padding-rythm;
		}

		&--image {
			position: absolute;
			top: $padding-rythm;
			left: $padding-rythm;
			width: calc((100% / 5) * 3);
			height: calc(600px - (#{$padding-rythm} * 2));
		}

		&--text {
			font-size: $fs-body-large;
			width: 50%;
			right: $padding-rythm;
			top: 50%;
			transform: translateY(-50%);
			margin: 0;
			bottom: auto;

			&-inner {
				padding: $padding-rythm $padding-rythm / 1.5;
			}
		}

		&--shadow {
			z-index: 5;
			position: absolute;
			background: $stripe-60;
			width: 100%;
			height: 100%;
			top: 10px;
			left: 10px;
		}

		&__home {
			.banner {
				&--image {
					width: calc(((100% / 3) * 2) - #{$padding-rythm});
					height: 600px;
					bottom: -$padding-rythm;
					top: auto;
				}

				&--text {
					right: $padding-rythm * 1.5;
				}
			}
		}

		&__blog-post {
			.banner {
				&--text {
					width: 770px;
				}
			}
		}

		&--blog {
			&-intro {
				font-size: 30px;
				margin-top: $padding-rythm / 2;
			}
			&-topics {
				margin-top: $padding-rythm / 2;
				font-size: $fs-body-sm;
				& span {
					display: block;
				}
			}
		}

	}
}
