.tag {
    text-decoration: underline;
    text-underline-offset: 4px;
    @supports not (text-underline-offset: 4px) {
        transition: all 150ms ease-in-out;
        text-decoration: none;
        line-height: 1.2;
        border-bottom: 1px solid currentColor;

        &:hover,
        &:active {
            border-color: $blue;
        }
    }
}