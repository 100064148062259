.captioned-media {
    margin: $padding-rythm 0;
	&--asset {
		width: 100%;
		height: auto;
		display: block;
	}
	&--caption {
		font-size: $fs-body-sm;
		padding-top: $padding-rythm / 2;
	}
}
