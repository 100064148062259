.portal-documents {
	&--title {
		font-family: $curly-font;
		text-transform: capitalize;
		margin: 40px 0 10px;
	}

	&--intro {
		color: black;
	}

	&--list {
		padding: 10px 20px;
		background: white;
		box-shadow: $drop-shadow;

		&-container {
			break-inside: avoid;
		}

		&-item {
			padding: 5px 0;
			display: flex;
			align-items: center;
			justify-content: space-between;

			&:not(:last-of-type) {
				border-bottom: 1px solid $border-colour;
			}

			& > a {
				text-decoration: none;
			}

			& > span {
				color: rgba(black, 0.5);
				text-transform: uppercase;
				font-size: 0.8em;
			}
		}
	}

	@include from($bp-small) {
		column-count: 2;
	}
}
