.staff-table {
	width: 100%;
	margin-top: 15px;
	
	th {
		text-align: left;
	}

	tr {
		border-bottom: 2px solid black;
	}

	td, th {
		padding: 8px;
	}

	tbody > tr {
		&:hover {
			background: darken(white, 5%);
		}
	}
}

.staff-directory {
	@include flex-grid-container;
	padding: 10px;
	background-color: white;
	box-shadow: $drop-shadow;

	@include from($bp-small) {
		padding: $padding-rythm;
	}

	&--filters {
		float: right;
	}
}

.profile {
	@include flex-grid-item(2, 10px);
	font-size: 14px;
	color: black;
	margin-bottom: $padding-rythm / 2;
	overflow: hidden;

	&--title {
		margin: 15px 0;
		font-size: 16px;
	}

	&--image {
		max-width: 100%;
	}

	&--contact-info {
		display: block;
	}

	&--position {
		margin-bottom: 10px;
		display: block;
	}

	&--bio {
		margin-top: 10px;
	}

	@include from($bp-small) {
		@include flex-grid-item(4, 20px);
		margin-bottom: $padding-rythm;
	}

	@include from($bp-large) {
		@include flex-grid-item(6, 20px);
	}
}
