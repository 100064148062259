@mixin flex-grid-container() {
	display: flex;
	flex-wrap: wrap;
};

@mixin flex-grid-item($col-count, $gutter-size) {
  // When we deprecate IE11 support change width to flex-basis. -JS
	flex-basis: auto;
	width: calc(#{100% / $col-count} - #{$gutter-size * ($col-count - 1) / $col-count});
	&:nth-child(n){
		margin-right: $gutter-size;
		margin-top: 0;
	};
	&:nth-child(#{$col-count}n) {
		margin-right: 0;
	}
	&:nth-child(n+#{$col-count+1}) {
		margin-top: $gutter-size;
	}
};
