.content-block {
	display: flex;
	padding: $padding-rythm / 2 0;
	align-items: flex-start;
	flex-direction: column;

	&--text {
		margin: 0 10px;
		margin-bottom: $padding-rythm / 3;
	}

	& .quote-block {
		margin: 0 20px $padding-rythm / 2.5 10px;
	}

	&--image {
		margin: 0 auto;
		margin-bottom: $padding-rythm / 3;
	}


	& .quote-block {

		& > span {
			margin-top: 15px;
			display: block;
			font-weight: bold;
			font-family: $body-font;
			font-size: $fs-body;
		}
	}

	&--image {
		&__purple {
			background-color: $purple;
		}

		&__pink {
			background-color: $pink;
		}
		& > img {
			mix-blend-mode: luminosity;
			width: 100%;
			height: auto;
		}
	}

	&--text {
		margin: 0 10px;

		& > h2 {
			margin-bottom: $padding-rythm / 2;
			max-width: calc(100% - 45px);
			& > img {
				height: 1em;
				width: auto;
				margin-left: 6px;
			}
		}
	}

	@include from($bp-small) {
		padding: 1.5 * $padding-rythm 0;
		flex-direction: row;

		&--text {
			margin: 0 $padding-rythm * 1.2;
			max-width: 50%;
		}

		& .quote-block,
		&--image {
			margin-right: auto;
			margin-left: $padding-rythm;
			margin-bottom: 0;
		}

		& .quote-block {
			max-width: 525px;
		}

		&__right {
			flex-direction: row-reverse;

			& .quote-block,
			.content-block--image {
				margin-left: auto;
				margin-right: $padding-rythm;
				margin-bottom: 0;
			}
		}
	}

	@include from($bp-large){
		.quote-block {
			max-width: 656.25px;
		}
	}
}
