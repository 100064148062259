.header {
	@extend %wrap;
    display: flex;
    justify-content: space-between;
	height: $padding-rythm;
	background-color: $purple;

	&--logo {
		height: 100%;

		&-small {
			height: 100%;
			width: auto;
			padding: 10px 0 10px 20px;
		}

		&-large {
			display: none;
			height: 100%;
			width: auto;
			padding: $padding-rythm / 3 0 $padding-rythm / 3 $padding-rythm * 1.5;
		}
	}


	&--nav {
		height: $padding-rythm;
		align-items: center;
		display: block;
		opacity: 0;
		transition: opacity 300ms ease-in-out;
        font-size: $fs-body-sm;

		display: block;
		position: absolute;
		right: 0;
		top: $padding-rythm;
		z-index: 100;
		background: white;
		text-align: center;

		&.is-active {
			opacity: 1;
		}
	}

	&--link {
		margin: 0;
		text-decoration: none;
		white-space: nowrap;
		background: white;
		padding: 10px 30px;
		display: block;

		&__current,
		&:hover {
			text-decoration: underline;
		}

		&__facebook {
			display: none;
			color: $purple;
			& > svg {
				transform: translate(-5px, 6px)rotate(-15deg);
				width: 25px;
				height: 25px;
			}
		}

		&__help {
			margin-right: 0;
			font-size: 13px;
			font-size: $fs-body-large;
			font-family: $curly-font;
			background: $pink;
			color: white;
			height: 100%;
			display: flex;
			align-items: center;
			cursor: pointer;
			justify-content: center;

			&:hover {
				color: white;
			}
		}
	}

	&--mobile-controls {
		display: flex;
		align-items: center;
		overflow: hidden;
	}

	&--fb-mobile {
		height: 100%;
		background: lighten($purple, 10%);
		padding: 18px;
		& > svg {
			transform: rotate(-15deg);
			width: 25px;
			height: 25px;
		}
	}

    &--mobile-menu {
		background: white;
		padding: 20px;
	}

	&--hamburger {
		$size: 20px;
		$stripe: $size / 5;
		width: $size;
		height: $size;
		position: relative;

		&:before, &:after {
			transition: all 300ms ease-in-out;
			content: '';
			width: 100%;
			height: $stripe;
			display: block;
			position: absolute;
			left: 0;
			transform-origin: center;
		}

		&:before {
			background-color: $purple;
			top: 0;
			box-shadow: 0px $stripe * 2 $purple;
		}

		&:after {
			background-color: $purple;
			bottom: 0;
		}

		&.is-active {
			&:before {
				transform: translateY($stripe * 2) rotate(45deg);
				box-shadow: 0px $stripe * 2 rgba($purple, 0);
			}

			&:after {
				transform: translateY(-$stripe * 2) rotate(-45deg);
			}
		}
	}
}

// FIXME find a better breakpoint
@include from($bp-large) {
	.header {
		background-color: white;
		height: $padding-rythm * 1.5;
		&--nav {
			height: $padding-rythm * 1.5;
			font-size: $fs-body;
			padding: 0;
			display: flex;
			top: 0;
			text-align: left;
			opacity: 1;
		}

		&--link {
			padding: 0;
			margin: 0 15px;

			&__facebook {
				display: inline-block;
			}

			&__help {
				font-size: 30px;
				margin: 0;
				padding: 0 45px;
			}
		}

		&--logo {
			&-large {
				display: block;
			}

			&-small {
				display: none;
			}
		}

		&--mobile-controls {
			display: none;
		}
	}
}
