body,
html {
	padding: 0;
	margin: 0;
}

a {
	display: block;
	text-decoration: none;
}

ul,
ol {
	margin: 0;
	padding: 0;
	display: block;
}

ul li {
	list-style: none;
}

