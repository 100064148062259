.field {
	$offset: 8px;
	margin-bottom: $padding-rythm / 3 + $offset;

	&--label {
		display: block;
		font-weight: bold;
	}

	&--help {
		display: block;
		margin-bottom: 6px;
		font-size: 16px;
	}

	&--error {
		margin-top: 5px;
		font-size: 16px;
		color: red;
	}

	&--container {
		@include box-shadow-stripe($offset);

		& > select,
		& > input:not([type="checkbox"]),
		& > textarea {
			outline: 0;
			border: 3px solid $purple;
			padding: 0.5em;
			color: $purple;
			width: 100%;
		}

		& > input:not([type="checkbox"]) {
			height: 50px;
		}

		& > input[type="checkbox"] {
			border: 2px solid $purple;
			border-radius: 5px;
			height: 18px;
			width: 18px;
			appearance: none;

			&:checked {
				background: url("../svgs/checkbox.svg");
				background-size: 14px 14px;
				background-repeat: no-repeat;
			}
		}

		& > textarea {
			height: $padding-rythm * 3;
		}
	}

	&__no-strip {
		& .field--container:after {
			background: none;
		}
	}

	@include from($bp-small) {
		font-size: $fs-body-large;

		&__intranet {
			font-size: 20px;
		}

		& > input[type="text"] {
			height: $padding-rythm;
		}
	}
}
