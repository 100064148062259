.content-page {
	@extend %wrap;

	.rich-text {
		margin-top: $padding-rythm;
	}

	&--form {
		max-width: 60%;
		margin: $padding-rythm auto 0 auto;
	}
}
