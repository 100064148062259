/**
 * Media Queries - *Try* and make everything fit within these - use @from/to/between
 */
 $bp-small: 1024;
 $bp-medium: 1024;
 $bp-large: 1280;
 $bp-very-large: 1400;
 
 
 /**
 * Font definitions
 */
 $defaultFontSize: 1;
 
 $body-font: "ClearSans", sans-serif;
 $heading-font: "ClearSans", sans-serif;
 $curly-font: 'Caveat', cursive;
 
 $base-font-size: 18;
 
 /**
 * Font Sizes
 */
 
 // If the designs are displayed at 75% in indesign
 // - Take the text size in pt
 // - Make it px (10pt > 10px)
 // - Multiply it it by the variable (10px * $pt-scale-factor)
 // It will come out the (closest) correct size in px.
 $pt-scale-factor: 1;
 $heading-offset: -0.2em;
 
$fs-h1: 56px;
$fs-h2: 47px;
$fs-body-large: 24px;
$fs-body: #{$base-font-size}px;
$fs-body-sm: 15px;
$fs-h1-sm: 26px;
$fs-h2-sm: 21px;
/**
 * Transitions
 */
$base-anim-rythm: 300ms;
$transition-default: all $base-anim-rythm ease-in-out;


/**
 * Padding
 * Usage: padding: $padding-rythm*2
 */
$padding-rythm: 60px;

$purple: #4a2d8e;
$pink: #d23460;
$blue: #3ad0c5;
$sand: #f1f1f1;
$gray: #e5e5e5;
$light-grey: #F7F7F7;
$border-colour: $sand;

$stripe: url('../images/stripe.png');
$stripe-60: url('../images/stripe-60.png');
$stripe-25: url('../images/stripe-25.png');

$drop-shadow: 2px 2px 1px rgba(#e5e5e5, 0.8);
/**
 * z-index stack
 */
$zi-layer-1: 20;
$zi-layer-2: 40;
$zi-layer-3: 60;
$zi-layer-4: 80;
$zi-layer-nav: 200;


/**
 * Misc
 */
$max-width:  1340px;
$defaultFontSize: 1;
$transition-default: all 300ms ease-in-out;
