.portal-header {
	background-color: $purple;

	&--top, &--bottom {
		height: $padding-rythm;
		width: 100%;
		display: flex;
		justify-content: space-between;
		padding: 0 $padding-rythm / 2;
	}

	&--bottom {
		background-image: $stripe-25;
	}

	&--logo {
		display: none;
		height: 100%;
		align-items: center;

		& > img {
			height: 70%;
			width: auto;
		}

		& > h1 {
			color: white;
			font-family: $curly-font;
			margin-left: 15px;
			font-size: $fs-h2;
			font-weight: normal;
		}
	}

	&--navigation {
		display: flex;
		color: white;
		align-items: center;
		flex-wrap: wrap;
	}

	&--nav-link {
		& > svg {
			display: inline-block;
			height: 8px;
			width: 8px;
			position: relative;
			top: -3px;
			right: -3px;
		}

		& > a {
			text-decoration: none;
			&:hover {
				text-align: underline;
			}
		}

		&:not(:last-of-type) {
			margin-right: 10px;
		}

		&__current {
			& > a {
				text-decoration: underline;
			}
		}
	}

	&--flags {
		display: none;
		align-items: center;

		& > img {
			height: 20px;
			width: auto;

			&:not(:last-of-type) {
				margin-right: 10px;
			}
		}
	}

	&--controls {
		display: flex;
		align-items: center;

		& > span {
			color: rgba($sand, 0.7);
			margin-right: 10px;
		}

		& > a {
			color: white;
			text-decoration: none;

			&:not(:last-of-type) {
				margin-right: 10px;
			}

			&:hover {
				text-decoration: underline;
			}
		}
	}

	@include from($bp-small) {
		&--top, &--bottom {
			padding: 0 $padding-rythm;
		}

		&--flags {
			display: flex;
		}
		&--logo {
			display: flex;
		}

		&--nav-link {
			&:not(:last-of-type) {
				margin-right: $padding-rythm / 2;
			}
		}
	}
}
