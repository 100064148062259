.footer {
	background-color: #fafafa;
	padding-top: 20px;
	margin-top: $padding-rythm;

	&__home {
		background-color: white;
	}

	&--top, &--bottom-wrap {
		display: flex;
		flex-direction: column-reverse;
		@extend %wrap;
	}

	#map {
		width: 320px;
		height: 320px;
		margin: 40px auto 0 auto;
	}

	&--column {
		flex-basis: 100%;
		padding: 0 10px;

		& > h2 {
			margin-bottom: 10px;
		}
	}


	&--services {
		margin-bottom: 40px;
	}

	&--bottom {
		display: none;
		background: $stripe-25;
		font-size: $fs-body-large;

		&-wrap {
			flex-wrap: wrap;
			padding-bottom: $padding-rythm;
		}
	}

	&--contact {
		display: block;
		margin-bottom: $padding-rythm / 3;
		font-size: 22px;

		&-label {
			display: block;
			font-weight: bold;
		}

		&-value {
			display: block;
		}
	}

	&--funding {
		flex-basis: 100%;
		padding: 0 $padding-rythm;
		font-size: $fs-body;
	}
}

@include from($bp-small) {
	.footer {
		padding-top: 0;
		margin-top: 3 * $padding-rythm;

		&__home {
			margin-top: 0;
		}

		& #map {
			margin-top: -100px;
			width: calc(100% + #{$padding-rythm});
			padding: 0;
		}

		&--column{
			flex-basis: calc(100% / 3);
		}

		&--services {
			margin-bottom: 0;
			& > li {
				margin-bottom: $padding-rythm / 4;
			}
		}

		&--column {
			padding: $padding-rythm;

			& > h2 {
				margin-bottom: $padding-rythm / 2;
			}
		}

		&--top, &--bottom-wrap {
			flex-direction: row;
		}

		&--bottom {
			display: block;
		}
	}
}
