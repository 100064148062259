.l-blog-post {
    @extend %wrap;
    padding: 0 10px;
    margin-top: $padding-rythm + 10px;

    @include from($bp-medium) {
        margin-top: $padding-rythm * 2;
        padding: 0 $padding-rythm * 1.5;
        &--content {
            max-width: calc(50% + #{$padding-rythm});
        }
    }
}