.dashboard {
	display: flex;

	&--column {
		flex-basis: calc(100% / 3);
		&:not(:last-of-type) {
			margin-right: $padding-rythm / 2;
		}
	}

	&--heading {
		margin-bottom: 20px;
		& > h2 {
			font-weight: normal;
			font-family: $curly-font;
			font-size: $fs-h2;
			display: inline-block;
		}

		& > span {
			margin-left: 10px;
			font-size: 0.8em;
		}

		& + .dashboard--subheading {
			margin-top: 0 !important;
		}
	}

	&--subheading {
		display: block;
		font-family: $curly-font;
		margin: 40px 0 5px;
	}

	&--news-item {
		background-color: white;
		padding: 20px;
		color: black;
		box-shadow: $drop-shadow;

		+ .dashboard--news-item {
			margin-top: 1px;
		}
	}

	@include to($bp-small) {
		flex-wrap: wrap;

		&--column {
			flex-basis: 100%;
		}

		&--heading {
			margin: 20px 0;
		}
	}
}
