@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-Regular-webfont.eot');
    src: url('../fonts/ClearSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ClearSans-Regular-webfont.woff') format('woff'),
         url('../fonts/ClearSans-Regular-webfont.ttf') format('truetype'),
         url('../fonts/ClearSans-Regular-webfont.svg#clear_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-Italic-webfont.eot');
    src: url('../fonts/ClearSans-Italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ClearSans-Italic-webfont.woff') format('woff'),
         url('../fonts/ClearSans-Italic-webfont.ttf') format('truetype'),
         url('../fonts/ClearSans-Italic-webfont.svg#clear_sansitalic') format('svg');
    font-weight: normal;
    font-style: italic;

}

@font-face {
    font-family: 'ClearSans';
    src: url('../fonts/ClearSans-Bold-webfont.eot');
    src: url('../fonts/ClearSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/ClearSans-Bold-webfont.woff') format('woff'),
         url('../fonts/ClearSans-Bold-webfont.ttf') format('truetype'),
         url('../fonts/ClearSans-Bold-webfont.svg#clear_sansbold') format('svg');
    font-weight: bold;
    font-style: normal;
}
