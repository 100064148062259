.contact-page {
	padding-top: $padding-rythm / 2;
	display: flex;
	flex-wrap: wrap;
	@extend %wrap;

	&--form,
	&--quotes {
		flex-basis: 100%;
	}

	&--form {
		margin: 0 10px $padding-rythm / 3 10px;

		&-title {
			margin: 20px 0 20px 0;
			& > img {
				width: 25px;
			}
		}

		&-contact {
			margin-top: 20px;
			& > .btn {
				margin: 0 auto;
				display: flex;
			}
		}
	}

	& .quote-block {
		margin: 0 20px 56px 10px;
	}

	&--thankyou {
		@extend %wrap;
		background: $purple;
		padding: $padding-rythm / 3 0;
		text-align: center;
		color: white;
		margin-top: 20px;
	}

	@include from($bp-small) {
		padding-top: $padding-rythm;
		flex-wrap: nowrap;
		&--form {
			margin: 0 $padding-rythm;
			flex-basis: calc((100% / 3) * 2);

			&-title {
				margin: $padding-rythm * 1.5 0 $padding-rythm 0;

				& > img {
					width: 50px;
					margin-bottom: -4px;
				}
			}

			&-contact {
				margin-top: $padding-rythm;
			}
		}

		& .quote-block {
			margin: 0 20px 96px 10px;
		}

		&--thankyou {
			margin-top: $padding-rythm / 3;
		}

		&--quotes {
			flex-basis: calc(100% / 3);
		}
	}
}
