.blog-post-listing {
    position: relative;

    &--text {
        position: relative;
        background-color: $light-grey;
        padding: 20px;
    }

    &--title {
        font-weight: bold;
        text-decoration: none;
        font-size: 16px;
        line-height: 20px;
    }

    &--tags {
        margin-top: 10px;
        font-size: 12px;

        & > a {
            text-transform: uppercase;
            margin-right: 8px;
        }
    }
    

    & + & {
        margin-top: 15px;
    }

    &__featured {
        display: flex;
        flex-direction: column;

        .blog-post-listing {
            &--text {
                background-color: $purple;
                color: white;
            }
        }
    }

    @include to($bp-medium) {
        display: grid;
        grid-template-columns: 1fr 2fr;

        &--image {
            width: 100%;
            height: auto;
        }

        &__featured {
            display: flex;
            .blog-post-listing {
                &--title {
                   font-size: $fs-body-large; 
                }
                &--image {
                    display: flex;
                    align-items: flex-end;
                }
            }
        }
    }

    @include from($bp-medium) {
        padding: 20px 0;
        & + & {
            margin-top: $padding-rythm / 2;
        }

        &--image {
            height: 100%;
            width: 35%;
            position: absolute;
            left: 0;
            top: 0;

            &-asset {
                position: absolute;
                object-fit: cover;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
            }
        }

        &--text {
            min-height: 180px;
            width: 70%;
            margin-left: auto;
            padding: $padding-rythm / 2;
        }

        &--title {
            font-size: 30px;
            line-height: 36px;
            margin-top: $heading-offset;
        }

        &--tags {
            margin-top: 20px;
            font-size: 14px;
        }

        &__featured {
            justify-content: center;
            padding: 0;
            .blog-post-listing {
                &--title {
                    font-size: $fs-h2;
                    line-height: 50px;
                }
                &--image {
                    width: calc(55% + #{$padding-rythm});
                    right: 0;
                    left: auto;
                    
                    &-asset {
                        padding: 40px 0;
                    }
                }

                &--text {
                    width: calc(50% - #{$padding-rythm});
                    padding: 60px 40px;
                    margin-left: 0;
                    margin-right: auto;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                }

                &--tags {
                    margin-top: $padding-rythm;
                }
            }
            
        }
    }
}