*,
*:before,
*:after {
	box-sizing: border-box;
}

html {}

body {
}
