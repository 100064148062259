.btn {
	background: $purple;
	color: white;
	outline: 0;
	border: 0;
	padding: 1em 1.5em;
	text-decoration: none;
	transition: background-color 150ms ease-in-out;

	&:hover,
	&:focus,
	&:active {
		background-color: lighten($purple, 10%);
	}

	&.waiting {
		cursor: wait;
	}

	&__alt {
		color: $purple;
		background: white;

		&:hover,
		&:focus,
		&:active {
			color: $purple;
			background-color: darken(white, 10%);
		}
	}
}

.btn-group {
}
