.portal-page {
	background-color: $sand;
	padding: 40px $padding-rythm / 2;
	min-height: calc(100vh - 2 * #{$padding-rythm});

	&--title {
		font-size: $fs-h2;
		font-family: $curly-font;
		display: inline-block;
		margin-bottom: $padding-rythm / 2;
	}

	&--content {
		background: white;
		padding: 20px;
		color: black;
		box-shadow: $drop-shadow;
		border: 1px solid $gray;
	}

	&--login-form {
		max-width: 50%;
		display: block;
		margin: $padding-rythm auto;

		& > .btn {
			display: block;
		}
	}

	&--form {
		margin-top: $padding-rythm;
		max-width: 75%;
		margin: $padding-rythm auto 0 auto;
	}

	@include from($bp-small) {
		padding: 80px $padding-rythm;
	}
}
