body {
	color: $purple;
	font-size:  $fs-body-sm;
	font-family: $body-font;
	line-height: 1.4;

	@include from($bp-small) {
		font-size: $fs-body;
	}
}

@mixin base-heading() {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	line-height: 1;
	font-family: $heading-font;
	margin: 0;
}

@mixin base-link() {
	transition: color 150ms ease-in;
	color: inherit;
	display: inline-block;
	position: relative;
	text-decoration: underline;
	&:hover,
	&:focus,
	&:active {
		color: $blue;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	@include base-heading;
}

h1 {
	font-size: $fs-h1-sm;
}

h2 {
	font-size: $fs-h2-sm;
}

h3 {}

h4 {}

h5 {}

a {
	@include base-link;
}

ul,
li {
	margin: 0;
}

img {
	width: 100%;
	height: auto;
}

p {
	margin: 0;
}

.richtext,
.rich-text {
	> * + * {
		margin-top: 1em;
	}

	> *:first-child {
		margin-top: 0;
	}
	> *:last-child {
		margin-bottom: 0;
	}

	a:not([class]) {
		@include base-link;
		display: inline;
	}

	ul, ol {
		padding-left: 1em;
	}

	ul {
		list-style: disc !important;
		li {
			list-style: disc !important;
		}
	}

	.responsive-object {
		position: relative;
		iframe,
		img {
			top: 0;
			left: 0;
			position: absolute;
			height: 100%;
			width: 100%;
		}
	}

}


@include from($bp-medium) {
	h1 {
		font-size: $fs-h1;
	}
	h2 {
		font-size: $fs-h2;
	}

	.richtext,
	.rich-text {
		> * + * {
			margin-top: 1em;
		}
		*:first-child {
			margin-top: 0;
		}
		*:last-child {
			margin-bottom: 0;
		}
	}
}

.visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
}
