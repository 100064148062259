@mixin box-shadow-stripe($offset: 16px) {
	position: relative;

	&:after {
		content: '';
		width: 100%;
		height: 100%;
		background: $stripe-60;
		z-index: -1;
		bottom: -$offset;
		right: -$offset;
		position: absolute;
	}
}
