.bulletins {
	background: white;
	padding: 20px;
	box-shadow: $drop-shadow;

	&--date {
		color: rgba(black, 0.6);
		margin-left: 15px;
	}

	&--listing {
		border-bottom: 1px solid $border-colour;
		padding: 5px 0;
		display: flex;
		align-items: center;

		& > svg {
			display: inline-block;
			height: 8px;
		}

		& > a {
			flex: 1;
			margin-left: 10px;
			text-decoration: none;
		}

		& > span {
			color: rgba(black, 0.3);
		}

		&__pinned {
			& > svg {
				height: 12px;
			}
		}

		&__read {
			& > a {
				color: black;
				&:hover {
					color: $blue;
				}
			}
		}
	}

	&--read-more {
		text-align: right;
		color: $purple;
		margin-top: 10px;
		width: 100%;
		font-size: $fs-body-sm;
	}
}
